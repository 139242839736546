<template>
  <DropdownComponent :title="orderingColumn.title">
    <div class="drop-list">
      <a
        class="drop-list__item"
        @click.prevent="selectType(type)"
        v-for="(type, i) in orderingColumns"
        :key="i"
      >
        {{ type.title }}
      </a>
    </div>
  </DropdownComponent>
</template>
<script>
import DropdownComponent from "components/inputs/DropdownComponent.vue";

export default {
  name: "OrderDropdownComponent",
  props: {
    orderingColumns: {
      type: Array,
    },
    orderingColumn: {
      type: Object,
    },
  },
  methods: {
    selectType(type) {
      this.$emit("change", type);
    },
  },
  components: { DropdownComponent },
};
</script>

<style lang="stylus">
.drop-list {
  display grid
  background var(--white)
  grid-gap 5px
  border: 1px solid var(--gray_light);
  border-radius 5px
  transition var(--transition)
  padding 5px 0
  width max-content

  &:hover {
    border-color: var(--main_color);
  }

  &__item {
    padding 5px 10px
    box-sizing border-box
  }
}
</style>
