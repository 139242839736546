<template>
  <DropdownComponent title="Сложность" @change="updateData">
    <div class="drop-form">
      <span class="drop-form__row">
        <span class="drop-form__title">Уровень сложности</span>
      </span>
      <div class="drop-form__row">
        <CheckboxComponent
          @change="setDifficulty($event, diff.code, form.types)"
          :checked="isCheckedDifficulty(form.types, diff.code)"
          v-for="(diff, i) in types"
          :key="i"
        >
          <div class="filter-item__option">
            <span>{{ diff.name }}</span>
          </div>
        </CheckboxComponent>
      </div>
      <div class="drop-form__row">
        <button type="button" @click="submit" class="btn btn--x-small btn--main">Применить</button>
      </div>
    </div>
  </DropdownComponent>
</template>

<script>
import DropdownComponent from "components/inputs/DropdownComponent.vue";
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";

export default {
  name: "DifficultyDropdownComponent",
  props: {
    selected_types: {
      type: Array,
    },
    types: {
      type: Array,
    },
  },
  data() {
    return {
      form: {
        types: [],
      },
    };
  },
  methods: {
    isCheckedDifficulty(formInput, value) {
      return formInput.indexOf(value) !== -1;
    },
    setDifficulty(checked, value, formInput) {
      if (checked) {
        formInput.push(value);
        return;
      }
      formInput.splice(
        formInput.findIndex((element) => element === value),
        1
      );
    },
    submit() {
      this.$emit("change", this.form.types);
    },
    updateData(value) {
      if (value) {
        this.form.types = this.selected_types.length ? this.selected_types : this.form.types;
      }
    },
  },
  components: { CheckboxComponent, DropdownComponent },
};
</script>

<style lang="stylus">
.drop-form {
  background var(--white)
  border: 1px solid var(--gray_light);
  border-radius 5px
  padding 20px
  display grid
  grid-gap 10px
  absolute left top
  width max-content

  &__row {
    display flex
    gap 15px
  }

  &__title {
    font-weight: 500;
    font-size: 1em;
    line-height: 19px;
  }

  &:hover {
    border-color: var(--main_color);
  }
}
</style>
