<template>
  <figure class="icon">
    <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
      <path
        clip-rule="evenodd"
        d="M17.0603 8.70711C17.4223 8.31658 17.4223 7.68342 17.0603 7.29289C16.6983 6.90237 16.1112 6.90237 15.7492 7.29289L10.842 12.5858L5.93476 7.29289C5.57269 6.90237 4.98567 6.90237 4.6236 7.29289C4.26153 7.68342 4.26153 8.31658 4.6236 8.70711L10.1864 14.7071C10.5484 15.0976 11.1355 15.0976 11.4975 14.7071L17.0603 8.70711Z"
        fill="black"
        fill-rule="evenodd"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "DownIcon",
};
</script>
