<template>
  <button
    v-if="isMobile && page !== total"
    class="pagination__button"
    type="button"
    @click="$emit('more', page + 1)"
  >
    Показать еще
  </button>
  <ul v-else-if="!isMobile" class="pagination">
    <li v-if="page !== 1">
      <button class="pagination__link" type="button" @click="$emit('change', 1)">
        <LeftIcon class="pagination__icon" />
      </button>
    </li>
    <li v-for="p in currentPages" :key="p">
      <button
        :class="{ 'pagination__link--active': page === p }"
        class="pagination__link"
        type="button"
        @click="$emit('change', p)"
      >
        {{ p }}
      </button>
    </li>
    <li v-if="page !== total">
      <button class="pagination__link" type="button" @click="$emit('change', total)">
        <LeftIcon class="pagination__icon pagination__icon--rotated" />
      </button>
    </li>
  </ul>
</template>

<script>
import LeftIcon from "./svg/LeftIcon.vue";

export default {
  name: "PaginationComponent",
  props: {
    page: Number,
    total: Number,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  computed: {
    currentPages() {
      let startPage, endPage;
      const maxPages = 4;
      if (this.total <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = this.total;
      } else {
        // total pages more than max so calculate start and end pages
        const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (this.page <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
        } else if (this.page + maxPagesAfterCurrentPage >= this.total) {
          // current page near the end
          startPage = this.total - maxPages + 1;
          endPage = this.total;
        } else {
          // current page somewhere in the middle
          startPage = this.page - maxPagesBeforeCurrentPage;
          endPage = this.page + maxPagesAfterCurrentPage;
        }
      }
      return Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);
    },
  },
  mounted() {
    this.isMobile = this.$store.getters.mobileCheck;
  },
  components: {
    LeftIcon,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.pagination {
  padding 0
  display flex
  justify-content center
  align-items center
  list-style none
  margin-top 32px
  margin-right auto
  border 1px solid var(--border_color)
  border-radius var(--radius)
  overflow: hidden;

  li:not(:last-child) &__link {
    border-right 1px solid var(--border_color)
  }

  &__icon {
    display flex
    align-items center
    justify-content center
    width 24px
    height 24px

    &--rotated {
      transform rotate(180deg)
    }
  }

  &__link {
    display flex
    align-items center
    justify-content center
    font-weight normal
    border none
    font-size 1em
    line-height 19px
    color var(--black)
    width 70px
    height 70px
    background none
    cursor pointer
    transition var(--transition)

    &--disabled {
      cursor: initial;
    }

    &:not(&--disabled):not(&--active):hover
    &:not(&--disabled):not(&--active):focus {
      background var(--main_color)
      color var(--white)
      border-color var(--main_color)

      .icon svg {
        svg(var(--white))
      }
    }

    &--active {
      cursor initial
      background var(--main_color)
      color var(--white)
    }
  }

  &__button {
    @extend .pagination__link
    margin-top 32px
    width 100%
    border-radius var(--radius)
    border 1px solid var(--border_color)
  }
}
</style>
