<template>
  <DropdownComponent title="Цена" @change="updateData">
    <div class="drop-form">
      <span class="drop-form__row">
        <span class="drop-form__title">Цена</span>
      </span>
      <div class="drop-form__row">
        <div class="price-range">
          <div class="price-range__input-container">
            <input
              class="price-range__input"
              v-model.number.trim="form.price_from"
              :min="priceRange.min"
              :max="form.price_to"
              :placeholder="`от ${priceRange.min}`"
              type="number"
            />
            <span class="price-range__currency">₽</span>
          </div>
          <div class="price-range__divider"></div>
          <div class="price-range__input-container">
            <input
              class="price-range__input"
              v-model.number.trim="form.price_to"
              :min="form.price_from"
              :max="priceRange.max"
              :placeholder="`до ${priceRange.max}`"
              type="number"
            />
            <span class="price-range__currency">₽</span>
          </div>
        </div>
      </div>
      <div class="drop-form__row">
        <button type="button" @click="submit" class="btn btn--x-small btn--main">Применить</button>
      </div>
    </div>
  </DropdownComponent>
</template>

<script>
import DropdownComponent from "components/inputs/DropdownComponent.vue";

export default {
  name: "PriceDropdownComponent",
  props: {
    price_from: {
      type: Number,
    },
    price_to: {
      type: Number,
    },
    priceRange: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        price_to: null,
        price_from: null,
      },
    };
  },
  methods: {
    submit() {
      this.$emit("change", {
        min: this.form.price_from,
        max: this.form.price_to,
      });
    },
    updateData(value) {
      if (value) {
        this.form.price_to = this.price_to || this.form.price_to;
        this.form.price_from = this.price_from || this.form.price_from;
      }
    },
  },
  components: { DropdownComponent },
};
</script>

<style lang="stylus">
.drop-form {
  background var(--white)
  border: 1px solid var(--gray_light);
  border-radius 5px
  padding 20px
  display grid
  grid-gap 10px
  absolute left top
  width max-content

  .price-range__input {
    max-width 120px
  }

  &__row {
    display flex
    gap 15px
  }

  &__title {
    font-weight: 500;
    font-size: 1em;
    line-height: 19px;
  }

  &:hover {
    border-color: var(--main_color);
  }
}
</style>
