<template>
  <main class="main courses-page" v-if="category">
    <section class="container courses-page__container">
      <div class="courses-page__header">
        <h1 class="courses-page__title">{{ category.title }}</h1>
        <span class="courses-page__count">
          {{ category.courses_count }}
        </span>
        <button class="btn btn--gray-outline btn--small courses-page__settings-btn" @click="showFilterModal">
          <SettingsIcon />
        </button>
      </div>
      <div class="courses-page__content">
        <CoursesFilterComponent
          @change="filter"
          ref="filter"
          :loading="loading"
          :types="types"
          :selected_types="filterData.levels"
          :price_range="priceRange"
          :selected_price_range="{
            min: filterData.price_from,
            max: filterData.price_to,
          }"
        />
        <div class="courses-page__list">
          <div class="courses-page__list-header">
            <div class="courses-page__list-control courses-page__no-mobile">
              <router-link :to="{ name: 'courses' }" @click.prevent class="tag">
                <span class="tag__title">{{ category.title }}</span>
                <CloseIcon />
              </router-link>
              <DifficultyDropdownComponent
                @change="changeDifficulty"
                :selected_types="filterData.levels"
                :types="types"
              />
              <PriceDropdownComponent
                @change="changePriceRange"
                :price-range="priceRange"
                :price_from="filterData.price_from"
                :price_to="filterData.price_to"
              />
            </div>
            <div class="courses-page__list-control">
              <OrderDropdownComponent
                :ordering-column="orderingColumn"
                :ordering-columns="orderingColumns"
                @change="selectType"
              />
            </div>
          </div>
          <div class="courses-list courses-list--active" v-if="showCoursesBlock">
            <CourseComponent v-for="(course, i) in courses.data" :key="i" :course="course" />
          </div>
          <div class="courses-list" v-else>Список пуст</div>
          <PaginationComponent
            v-if="showPagination"
            :page="page"
            :total="Math.ceil(courses.paginatorInfo.total / first)"
            @change="paginate"
            @more="paginate($event, true)"
          />
        </div>
      </div>
    </section>
  </main>
  <PageNotFound v-else />
</template>

<script>
import CoursesFilterComponent from "../components/CoursesFilterComponent.vue";
import SettingsIcon from "@/components/svg/SettingsIcon.vue";
import PaginationComponent from "components/Pagination.vue";
import CourseComponent from "components/CourseComponent.vue";
import CloseIcon from "components/svg/CloseIcon.vue";
import PriceDropdownComponent from "../components/PriceDropdownComponent.vue";
import DifficultyDropdownComponent from "../components/DifficultyDropdownComponent.vue";
import OrderDropdownComponent from "../components/OrderDropdownComponent.vue";
import PageNotFound from "@/views/PageNotFound.vue";

let _isMobile = false;
let orderingColumns = [
  {
    column: "popularity",
    title: "Популярное",
  },
  {
    column: "favorite",
    title: "Избранное",
  },
  {
    column: "trend",
    title: "Трендовое",
  },
];
export default {
  name: "CoursesCategoryPage",
  async asyncData({ apollo, store, isMobile, route, res }) {
    if (!route.params && !route.params.link) {
      res.status(404);
      return;
    }
    _isMobile = isMobile;
    await store.dispatch("COURSES_CATEGORY_PAGE", {
      apollo: apollo.defaultClient,
      variables: {
        category: route.params.link,
        categories_by_link: [route.params.link],
        page: 1,
        ordering: [
          {
            orderColumn: orderingColumns[0].column,
            orderBy: "desc",
          },
        ],
        first: _isMobile ? 8 : 12,
      },
      loadingType: store.state._loading_types.DEFAULT,
    });
    if (!store.state.courses_category_page.category) {
      res.status(404);
    }
  },
  data() {
    return {
      filterData: {
        categories: [],
        price_from: null,
        price_to: null,
        levels: [],
      },
      orderingColumns: orderingColumns,
      orderingColumn: orderingColumns[0],
      page: 1,
      loading: false,
      first: _isMobile ? 8 : 12,
    };
  },
  computed: {
    showCoursesBlock() {
      return this.courses.data && this.courses.data.length;
    },
    showPagination() {
      return (
        this.showCoursesBlock && !this.loading && Math.ceil(this.courses.paginatorInfo.total / this.first) > 1
      );
    },
    category() {
      return this.$store.state.courses_category_page.category;
    },
    categories() {
      return this.$store.state.courses_category_page.categories || [];
    },
    types() {
      return this.$store.state.courses_category_page.types || [];
    },
    priceRange() {
      return this.$store.state.courses_category_page.courses_min_max_price || {};
    },
    courses() {
      return this.$store.state.courses_category_page.courses || [];
    },
  },
  methods: {
    async changeDifficulty(data) {
      if (!this.loading) {
        this.loading = true;
        this.filterData.levels = data;
        await this.reloadData();
        this.$refs.filter.updateData();
        this.loading = false;
      }
    },
    async changePriceRange(data) {
      if (!this.loading) {
        this.loading = true;
        this.filterData.price_from = data.min;
        this.filterData.price_to = data.max;
        await this.reloadData();
        this.$refs.filter.updateData();
        this.loading = false;
      }
    },
    async selectType(type) {
      if (!this.loading) {
        this.loading = true;
        this.orderingColumn = type;
        await this.reloadData();
        this.loading = false;
      }
    },
    showFilterModal() {
      this.$store.state._modals.push({
        component: CoursesFilterComponent,
        options: {
          showClose: true,
          loading: this.loading,
          types: this.types,
          selected_types: this.filterData.levels,
          price_range: this.priceRange,
          selected_price_range: {
            min: this.filterData.price_from,
            max: this.filterData.price_to,
          },
          callback: this.filter,
        },
      });
    },
    async filter(form) {
      if (!this.loading) {
        this.loading = true;
        this.filterData.categories = form.categories.value;
        this.filterData.price_from = form.price_from.value;
        this.filterData.price_to = form.price_to.value;
        this.filterData.levels = form.levels.value;
        await this.reloadData();
        this.loading = false;
      }
    },
    async paginate(page, type, more) {
      if (!this.loading) {
        this.page = page;
        this.loading = true;
        await this.reloadData(more);
        this.loading = false;
      }
    },
    async reloadData(more) {
      window.scrollTo(0, 0);
      let loadingType = this.$store.state._loading_types.PAGINATION;
      if (more) {
        loadingType = this.$store.state._loading_types.LOAD_MORE;
      }
      await this.$store.dispatch("COURSES_CATEGORY_PAGE", {
        apollo: this.$apollo,
        loadingType: loadingType,
        variables: {
          category: this.$route.params.link,
          ordering: [
            {
              orderColumn: this.orderingColumn.column,
              orderBy: "desc",
            },
          ],
          categories_by_link: [this.$route.params.link],
          categories: this.filterData.categories,
          price_from: this.filterData.price_from,
          price_to: this.filterData.price_to,
          levels: this.filterData.levels,
          page: this.page,
          first: this.first,
        },
      });
    },
  },
  components: {
    PageNotFound,
    OrderDropdownComponent,
    DifficultyDropdownComponent,
    PriceDropdownComponent,
    CloseIcon,
    CourseComponent,
    PaginationComponent,
    CoursesFilterComponent,
    SettingsIcon,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/courses.styl"
</style>
