<template>
  <div class="course" v-if="course.category">
    <div class="course__header">
      <router-link
        :to="{ name: 'course_category', params: { link: course.category.link } }"
        class="course__category"
      >
        {{ course.category.title }}
      </router-link>
      <a
        :class="{ 'course__bookmark--active': favorite }"
        class="course__bookmark"
        title="Добавить в избранное"
        @click.prevent="$emit('favorite', !favorite)"
      >
        <BookmarkIcon />
      </a>
    </div>
    <div class="course__img-container">
      <router-link :to="{ name: 'course', params: { category: course.category.link, link: course.link } }">
        <img
          class="course__img"
          :alt="course.head_img | image_alt"
          :src="course.head_img | image($store.state._env.MEDIA_ENDPOINT)"
        />
      </router-link>
      <router-link
        :to="{ name: 'course_category', params: { link: course.category.link } }"
        class="course__category"
      >
        {{ course.category.title }}
      </router-link>
    </div>
    <router-link
      :to="{ name: 'course', params: { category: course.category.link, link: course.link } }"
      class="course__info"
    >
      <h2 class="course__title">{{ course.title }}</h2>
      <span class="course__description">{{ course.short_text }}</span>
    </router-link>
    <div class="course__bottom">
      <div class="course__price" v-if="course.price !== null">
        {{ course.price.toLocaleString("ru-RU", { maximumFractionDigits: 0 }) }} ₽
      </div>
      <div class="course__price" v-else>Бесплатно</div>
      <div class="course__footer">
        <router-link
          v-if="course.school"
          :to="{ name: 'school', params: { link: course.school.link } }"
          class="course__author"
        >
          <img
            class="course__author-circle"
            :src="course.school.head_img | image($store.state._env.MEDIA_ENDPOINT)"
            :alt="course.school.head_img | image_alt"
          />
          <span class="course__author-name">{{ course.school.title }}</span>
        </router-link>
        <a
          :class="{ 'course__bookmark--active': favorite }"
          class="course__bookmark"
          title="Добавить в избранное"
          @click.prevent="$emit('favorite', !favorite)"
        >
          <BookmarkIcon />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import BookmarkIcon from "components/svg/BookmarkIcon.vue";

export default {
  name: "CourseComponent",
  props: {
    favorite: {
      type: Boolean,
    },
    course: {
      type: Object,
      required: true,
    },
  },
  components: { BookmarkIcon },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/course.styl"
</style>
