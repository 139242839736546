<template>
  <div class="drop" v-click-outside="close">
    <a
      @click.prevent="toggle"
      class="drop__btn"
      :class="{
        'drop__btn--active': showDrop,
      }"
    >
      <span class="drop__title">{{ title }}</span>
      <DownIconSmall />
    </a>
    <div class="drop__container" ref="container" :class="{ 'drop__container--active': showDrop }">
      <slot />
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import DownIconSmall from "components/svg/DownIconSmall.vue";

export default {
  name: "DropdownComponent",
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {
      showDrop: false,
    };
  },
  methods: {
    close() {
      this.showDrop = false;
    },
    toggle() {
      this.showDrop = !this.showDrop;
      this.$emit("showDrop");
    },
  },
  components: { DownIconSmall },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.drop {
  box-sizing: border-box;
  display grid
  grid-gap 10px
  position relative

  &:hover &__btn {
    border-color: var(--main_color);
  }

  &__title {
    font-weight: normal;
    font-size: 1em;
    line-height: 19px;
    color: var(--black);
  }

  &__btn {
    display flex
    align-items center
    gap 5px
    padding 10px
    border-radius: 5px;
    border: 1px solid var(--gray_light);
    background: var(--white);

    &:hover {
      border-color var(--main_color)
    }

    &--active .icon {
      transform rotate(180deg)
    }

    .icon {
      width 24px
      height 24px
      transition var(--transition)

      svg {
        width 100%
        height 100%
        svg(var(--dark))
      }
    }
  }

  &__container {
    absolute bottom
    transform translateY(100%)
    visibility hidden
    opacity 0
    z-index 3
    transition var(--transition)

    &--active {
      transform translateY(calc(100% + 10px))
      visibility visible
      opacity 1
    }
  }
}
</style>
