<template>
  <div class="filter">
    <div class="filter__header">
      <h3 class="filter__title">Фильтрация</h3>
      <button type="button" v-if="showClose" class="filter__close" @click="$emit('close')">
        <CloseIcon />
      </button>
    </div>
    <form class="filter__form">
      <div class="filter__list">
        <div
          v-for="(parent, index) in categoriesSorted"
          :key="index"
          :class="{ 'filter-item--active': isActive(parent.id) }"
          class="filter-item"
        >
          <a class="filter-item__title" @click.prevent="setActive(parent.id)">
            <DownIcon />
            <span>{{ parent.title }}</span>
          </a>
          <div class="filter-item__inner">
            <a
              v-for="(children, i) in parent.children"
              :key="i"
              :class="{
                'filter-item__option--active': isCategorySelected(children.id),
              }"
              class="filter-item__option"
              @click.prevent="selectCategory(children.id)"
            >
              <span>{{ children.title }}</span>
              <span class="filter-item__option-number">{{ children.courses_count }}</span>
            </a>
          </div>
        </div>
        <div :class="{ 'filter-item--active': showPriceRange }" class="filter-item">
          <a class="filter-item__title" href="#" @click.prevent="showPriceRange = !showPriceRange">
            <DownIcon />
            <span>Цена</span>
          </a>
          <div class="filter-item__inner">
            <div class="price-range">
              <div class="price-range__input-container">
                <input
                  class="price-range__input"
                  v-model.number.trim="form.price_from.value"
                  :min="price_range.min"
                  :max="form.price_to.value"
                  :placeholder="`от ${price_range.min}`"
                  type="number"
                />
                <span class="price-range__currency">₽</span>
              </div>
              <div class="price-range__divider"></div>
              <div class="price-range__input-container">
                <input
                  class="price-range__input"
                  v-model.number.trim="form.price_to.value"
                  :min="form.price_from.value"
                  :max="price_range.max"
                  :placeholder="`до ${price_range.max}`"
                  type="number"
                />
                <span class="price-range__currency">₽</span>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="{ 'filter-item--active': showDifficulties }"
          class="filter-item filter-item__checkboxes"
          v-if="types.length"
        >
          <a class="filter-item__title" href="#" @click.prevent="showDifficulties = !showDifficulties">
            <DownIcon />
            <span>Сложность</span>
          </a>
          <div class="filter-item__inner">
            <CheckboxComponent
              v-for="(diff, i) in types"
              :key="i"
              @change="setDifficulty($event, diff.code, form.levels.value)"
              :error="!!form.levels.errors.length"
              :checked="isCheckedDifficulty(form.levels.value, diff.code)"
            >
              <div class="filter-item__option">
                <span>{{ diff.name }}</span>
                <span class="filter-item__option-number">{{ diff.courses_count }}</span>
              </div>
            </CheckboxComponent>
          </div>
        </div>
        <div class="filter__btn">
          <button class="btn btn--main btn--small" type="submit" @click.prevent="filter">
            <LoadingIndicator v-if="loading" title="Загрузка" />
            <template v-else>Показать результат</template>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import DownIcon from "components/svg/DownIcon.vue";
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";
import LoadingIndicator from "components/LoadingIndicator.vue";
import CloseIcon from "components/svg/CloseIcon.vue";

export default {
  name: "CoursesFilterComponent",
  props: {
    loading: {
      type: Boolean,
    },
    showClose: {
      type: Boolean,
    },
    callback: {
      type: Function,
    },
    categories: {
      type: Array,
      default() {
        return [];
      },
    },
    selected_categories: {
      type: Array,
      default() {
        return [];
      },
    },
    types: {
      type: Array,
      default() {
        return [];
      },
    },
    selected_types: {
      type: Array,
      default() {
        return [];
      },
    },
    price_range: {
      type: Object,
      default() {
        return {
          min: 0,
          max: 0,
        };
      },
    },
    selected_price_range: {
      type: Object,
      default() {
        return {
          min: 0,
          max: 0,
        };
      },
    },
  },
  data() {
    return {
      showPriceRange: false,
      showDifficulties: false,
      activeCategories: [],
      form: {
        categories: {
          value: [],
          errors: [],
        },
        price_from: {
          value: null,
          errors: [],
        },
        price_to: {
          value: null,
          errors: [],
        },
        levels: {
          value: [],
          errors: [],
        },
      },
    };
  },
  mounted() {
    this.updateData();
  },
  methods: {
    updateData() {
      if (this.selected_categories.length) {
        this.form.categories.value = JSON.parse(JSON.stringify(this.selected_categories));
      }
      if (this.selected_price_range.min) {
        this.form.price_from.value = JSON.parse(JSON.stringify(this.selected_price_range.min));
      }
      if (this.selected_price_range.max) {
        this.form.price_to.value = JSON.parse(JSON.stringify(this.selected_price_range.max));
      }
      if (this.selected_types) {
        this.form.levels.value = JSON.parse(JSON.stringify(this.selected_types));
      }
    },
    filter() {
      let form = JSON.parse(JSON.stringify(this.form));
      form.categories.value = form.categories.value.length ? form.categories.value : undefined;
      form.levels.value = form.levels.value.length ? form.levels.value : undefined;
      form.price_from.value = parseInt(form.price_from.value) || undefined;
      form.price_to.value = parseInt(form.price_to.value) || undefined;
      if (this.callback) {
        this.callback(form);
      }
      this.$emit("change", form);
      this.$emit("close");
    },
    isCheckedDifficulty(formInput, value) {
      return formInput.indexOf(value) !== -1;
    },
    setDifficulty(checked, value, formInput) {
      if (checked) {
        formInput.push(value);
        return;
      }
      formInput.splice(
        formInput.findIndex((element) => element === value),
        1
      );
    },
    setActive(id) {
      const index = this.activeCategories.indexOf(id);
      if (index !== -1) {
        this.activeCategories.splice(index, 1);
      } else {
        this.activeCategories.push(id);
      }
    },
    isActive(id) {
      return this.activeCategories.indexOf(id) !== -1;
    },
    selectCategory(id) {
      if (this.form.categories.value.indexOf(id) >= 0) {
        this.form.categories.value = [];
      } else {
        this.form.categories.value = [id];
      }
    },
    isCategorySelected(id) {
      return this.form.categories.value.indexOf(id) >= 0;
    },
  },
  computed: {
    categoriesSorted() {
      return this.categories
        .filter((category) => category.parent_id === null) // Получаю только главные
        .map((category) => {
          // Добавляю children с подкатегориями этой категории
          category.children = this.categories
            .filter((children) => children.courses_count) // Чтобы с курсами были
            .filter((children) => children.parent_id === category.id); // Беру подкатегории текущей категории
          return category;
        })
        .filter((category) => category.children.length); // Чтобы с дочерними категориями были
    },
  },
  components: {
    CloseIcon,
    LoadingIndicator,
    CheckboxComponent,
    DownIcon,
  },
};
</script>

<style lang="stylus">
@import '~@/styles/mixins/svg.styl';

.filter {
  border-radius: var(--radius);
  border: 1px solid var(--gray_light);
  background: var(--white);
  z-index: 1;
  max-width: 588px;
  width: 100%;

  &__header {
    padding: 20px;
    border-bottom: 1px solid var(--gray_light);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__close {
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;

    .icon {
      width: 24px;
      height: 24px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__title {
    font-size: 1em;
    line-height: 120%;
    margin: 0;
  }

  &__list {
    font-size: 0.875em;
    line-height: 120%;
  }

  &__btn {
    padding: 20px;
    grid-gap: 10px;
    display: grid;

    .btn {
      width: 100%;
    }
  }
}

.filter-item {
  border-bottom: 1px solid var(--gray_light);

  &--active {
    & ^[0]__title {
      padding-bottom: 15px;

      .icon {
        transform: rotate(0);
      }
    }

    & ^[0]__inner {
      visibility: visible;
      height: auto;
      overflow: visible;
      opacity: 1;
      padding-bottom: 10px;
    }
  }

  &__title {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 40px;

    .icon {
      position: absolute;
      left: 20px;
      transform: rotate(-90deg);
      transition: var(--transition);
      width: 14px;
      height: 14px;

      svg {
        svg(var(--gray));
      }
    }
  }

  &__inner {
    visibility: hidden;
    height: 0;
    overflow: hidden;
    opacity: 0;
    padding-left: 40px;
    padding-right: 20px;
    transition: all var(--transition);
    display: grid;
    grid-gap: 10px;
  }

  &__option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: var(--transition);
    width: 100%;

    &:hover, &--active {
      color: var(--main_color);
    }

    &-number {
      color: var(--gray);
    }

    &:hover &-number, &--active &-number {
      transition: var(--transition);
    }
  }
}

.price-range {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;

  &__input-container {
    position: relative;
  }

  &__currency {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--gray_dark);
  }

  input {
    padding: 10px 25px 10px 15px;
    background: var(--white);
    border: 1px solid var(--border_color);
    border-radius: var(--radius);
    color: var(--dark);
    width: 100%;
    box-sizing: border-box;
    outline: none;
    transition: var(--transition);
    font-weight: normal;
    font-size: 1em;
    line-height: 19px;

    &:focus, &:active {
      border-color: var(--main_color);
    }

    &::placeholder {
      font-family: var(--font_regular);
      color: var(--gray_dark);
    }

    &:focus {
      border-color: var(--main_color);
    }

    &::after {
      content: '₽'; // \20BD
      color: var(--gray_dark);
      display: block;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
    }
  }

  &__divider {
    min-width: 11px;
    height: 1px;
    background: var(--gray);
    margin: 0 10.5px;
  }
}
</style>
