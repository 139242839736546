<template>
  <figure class="icon-small">
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        clip-rule="evenodd"
        d="M9.33317 9.33301H10.6665C11.0345 9.33301 11.3332 9.63167 11.3332 9.99967V12.6663C11.3332 13.0343 11.0345 13.333 10.6665 13.333H9.33317C8.96517 13.333 8.6665 13.0343 8.6665 12.6663V9.99967C8.6665 9.63167 8.96517 9.33301 9.33317 9.33301Z"
        fill-rule="evenodd"
        stroke="#323232"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M14.0002 11.333H11.3335"
        stroke="#323232"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M8.66667 11.334H2"
        stroke="#323232"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        clip-rule="evenodd"
        d="M6.6665 6.66699H5.33317C4.96517 6.66699 4.6665 6.36833 4.6665 6.00033V3.33366C4.6665 2.96566 4.96517 2.66699 5.33317 2.66699H6.6665C7.0345 2.66699 7.33317 2.96566 7.33317 3.33366V6.00033C7.33317 6.36833 7.0345 6.66699 6.6665 6.66699Z"
        fill-rule="evenodd"
        stroke="#323232"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M2 4.66699H4.66667"
        stroke="#323232"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M7.3335 4.66699H14.0002"
        stroke="#323232"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "SettingsIcon",
};
</script>
