<template>
  <figure class="icon">
    <svg fill="none" height="25" viewBox="0 0 24 25" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 20V7.5C5 6.70435 5.31607 5.94129 5.87868 5.37868C6.44129 4.81607 7.20435 4.5 8 4.5H16C16.7956 4.5 17.5587 4.81607 18.1213 5.37868C18.6839 5.94129 19 6.70435 19 7.5V20C19.0001 20.0919 18.9748 20.1821 18.9269 20.2606C18.8791 20.3391 18.8105 20.4029 18.7288 20.445C18.647 20.4871 18.5552 20.5058 18.4635 20.4991C18.3718 20.4924 18.2838 20.4605 18.209 20.407L12.291 16.179C12.2061 16.1183 12.1044 16.0856 12 16.0856C11.8956 16.0856 11.7939 16.1183 11.709 16.179L5.791 20.406C5.7163 20.4595 5.62832 20.4913 5.53671 20.4981C5.4451 20.5048 5.3534 20.4862 5.27168 20.4442C5.18996 20.4023 5.12137 20.3386 5.07345 20.2603C5.02552 20.1819 5.00011 20.0919 5 20Z"
        stroke="#323232"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "BookmarkIcon",
};
</script>
